<template>
  <div class="grid">
    <div class="col-12">
      <Toast />
      <DataTable ref="dt" dataKey="id" :showGridlines="true" :value="entities" v-model:selection="currentEntity"
        v-model:filters="filters" filterDisplay="menu" :globalFilterFields="['name', 'unit']" :lazy="true"
        :paginator="true" :rows="20" :totalRecords="totalRecords"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[20, 50, 100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        responsiveLayout="scroll" @page="onPaging">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
              <Button type="button" icon="pi pi-filter-slash" :label="$t('common.clear')" class="p-button-outlined mr-1"
                @click="clearFilters()" />
              <Button :label="$t('button.create')" icon="pi pi-plus" class="p-button-success mr-1"
                @click="createEntity" />
              <FileUpload mode="basic" name="manifest[]" :customUpload="true" :auto="true" class="p-button-success mr-1"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :maxFileSize="10000000" @upload="onUpload" @uploader="itemUploader" />
            </div>

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column headerStyle="width:10%; min-width:10rem;" bodyStyle="text-align:center;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
              @click="editEntity(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
              @click="confirmDeleteEntity(slotProps.data)" />
          </template>
        </Column>
        <Column field="name" :header="$t('manifest.name')" :sortable="true" headerStyle="width:30%; min-width:20rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("manifest.name") }}</span>
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel }">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
              :placeholder="$t('common.search_by_field', [$t('manifest.name')])" />
          </template>
        </Column>
        <Column field="unit" :header="$t('manifest.unit')" :sortable="true" headerStyle="width:12%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("manifest.unit") }}</span>
            {{ slotProps.data.unit }}
          </template>
          <template #filter="{ filterModel }">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
              :placeholder="$t('common.search_by_field', [$t('manifest.unit')])" />
          </template>
        </Column>
        <Column field="electronic" :header="$t('manifest.electronic')" dataType="boolean" :sortable="true"
          headerStyle="min-width:5rem;">
          <template #body="{ data }">
            <span class="p-column-title">{{ $t("manifest.electronic") }}</span>
            <Checkbox v-model="data.is_electronic"></Checkbox>
          </template>
          <template #filter="{ filterModel }">
            <Checkbox v-model="filterModel.value" class="p-column-filter" />
            <label class="ml-2"> {{ $t("manifest.electronic") }} </label>
          </template>
        </Column>
      </DataTable>
      <DialogFormManifestItem :entity="currentEntity" :visible="entityDialog" @hide="entityDialog = false"
        @cancel="entityDialog = false" @save="saveEntity($event)" />
    </div>
  </div>
</template>

<script>
//https://github.com/SheetJS/sheetjs/blob/master/demos/vue/modify/src/App.vue
import ManifestItemService from "@/service/ManifestItemService.js";
import DialogFormManifestItem from "./DialogFormManifestItem.vue";
import filter from "@/mixins/filter";
import Consts from "@/utils/consts";
import * as XLSX from 'xlsx'
//import { XlsxJson, XlsxRead, XlsxTable, XlsxSheets, XlsxSheet} from 'vue3-xlsx'

export default {
  components: { DialogFormManifestItem },
  mixins: [filter],
  data() {
    return {
      entities: [],
      currentEntity: null,
      entityDialog: false,
      infos: {},
      filters: {},
      totalRecords: 100,
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS,
      },
      metaTable: {
        loading: false,
        entityName: "manifestitem",
        expander: false,
        searchable: true,
        filter: {
          filterDisplay: "menu",
        },
        paginator: {
          enable: true,
          rows: 20,
          rowsPerPageOptions: [20, 50, 100],
          totalRecords: 0,
          currentPageReportTemplate:
            "Showing {first} to {last} of {totalRecords} items",
        },
        columns: [
          {
            name: "name",
            label: "manifest.name",
            filter: true,
            headerStyle: "width:20%; min-width:20rem;",
          },
          {
            name: "unit",
            label: "manifest.unit",
            filter: true,
            headerStyle: "width:10%; min-width:10rem;",
          },
          {
            name: "is_electronic",
            label: "manifest.electronic",
            filter: true,
            type: "Boolean",
            headerStyle: "width:10%; min-width:10rem;",
          },
        ],
        tableActions: [
          {
            name: "create",
            icon: "pi pi-plus",
            style: "p-button mr-2",
          },
        ],
        itemActions: [
          {
            name: "edit",
            icon: "pi pi-pencil",
            style: "p-button-rounded p-button-success mr-2",
            condition: "mode != 'edit'",
          },
          {
            name: "delete",
            icon: "pi pi-trash",
            style: "p-button-rounded p-button-warning mt-2",
            condition: "mode != 'edit'",
          },
        ],
      },
    };
  },
  watch: {
    range(newValue, oldValue) {
      this._readSheet();
      console.log(newValue);
      console.log(oldValue);
    },
  },
  created() {
    this.initGlobalFilter(this.filters);
  },
  mounted() {
    this.initColumnFilters(this.$refs.dt, this.filters);
    this._loadEntities();
  },
  methods: {
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size = pageEvent.rows;
      this._loadEntities();
    },
    _loadEntities() {
      ManifestItemService.getAll(this.filterOptions).then((data) => {
        this.entities = data.content;
        this.totalRecords = data.totalElements;
      });
    },
    onTableAction(sAction) {
      if (sAction == "read") {
        this._readSheet();
      } else if (sAction == "upload") {
        this._upload();
      }
    },
    onItemAction(sAction, entity, index) {
      if (sAction == "save") {
        if (entity.id) {
          ManifestItemService.update(entity).then((response) => {
            this.entities[index] = response.data;
          });
        } else {
          let data = { ...entity };
          ManifestItemService.create(data).then((response) => {
            this.entities[index] = { mode: "edit" };
            this.entities.push(response.data);
          });
        }
      } else if (sAction == "edit") {
        entity.mode = "edit";
      } else if (sAction == "delete") {
        ManifestItemService.delete(entity.id).then(() => {
          this.entities.splice(index, 1);
        });
      }
    },
    saveEntity(entity) {
      if (entity.name.trim()) {
        if (entity.id) {
          this.entities[this.findIndexById(entity.id)] = entity;
          ManifestItemService.update(entity.id, entity).then((response) => {
            console.log(response);
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: this.$t("manifestitem.updated"),
              life: 3000,
            });
            this.currentEntity = response.data;
            this.entityDialog = false;
          });
        } else {
          ManifestItemService.create(entity).then((response) => {
            this.currentEntity = response.data;
            this.entities.push(response.data);
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: this.$t("manifestitem.created"),
              life: 3000,
            });
            this.entityDialog = false;
          });
        }
        this.selectedEntity = {};
      }
    },
    createEntity() {
      this.currentEntity = {};
      this.entityDialog = true;
    },
    editEntity(entity) {
      this.currentEntity = { ...entity };
      this.entityDialog = true;
    },
    onUpload(event) {
      console.log(event);
    },
    async itemUploader(event) {
      this.file = event.files ? event.files[0] : null;
      const buffer = await this.file.arrayBuffer();
      const data = XLSX.read(buffer, this.option);
      this.sheets = data.SheetNames;
      const sheetName = this.sheets.length > 0 ? this.sheets[0] : null;
      const sheet = data.Sheets[sheetName]
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if (sheetData.length > 0) {
        const { headers, body } = this.jsonSheet2ItemList(sheetData);
        console.log(headers, body);
        await ManifestItemService.import({ headers }, body);
        this.$toast.add({ severity: 'success', summary: 'Successful', detail: this.$t('manifestitem.imported'), life: 3000 });
      }
      console.log(sheetData);
    },
    jsonSheet2ItemList(sheetData) {
      if (Array.isArray(sheetData) && sheetData.length > 0) {
        //const columns = this.metaTable.columns.map(col => col.name);
        let row = sheetData[0];
        let headers = [], mapInds = {};
        for (let col in row) {
          mapInds[headers.length] = col;
          headers.push(row[col]);
        }
        let rowData = [];
        for (let r = 1; r < sheetData.length; r++) {
          row = [];
          const rowValue = sheetData[r];
          for (let i = 0; i < headers.length; i++) {
            row.push(rowValue[mapInds[i]]);
          }
          rowData.push(row);
          // const rowHash = hash(row);
          // if (!mapSet.has(rowHash)) {
          //   mapSet.add(rowHash)
          //   rowData.push(row);
          // }
        }
        return { headers, body: rowData };
      }
    },
    openConfirmDialog() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => { },
      });
    },
    confirmDeleteEntity(entity) {
      this.currentEntity = entity;
      this.deleteEntityDialog = true;
    },
    deleteEntity() {
      this.entities = this.entities.filter(
        (val) => val.id !== this.currentEntity.id
      );
      this.deleteEntityDialog = false;
      this.currentEntity = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("user.deleted"),
        life: 3000,
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.entities.length; i++) {
        if (this.entities[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteEntitiesDialog = true;
    },
    deleteSelectedEntities() {
      this.entities = this.entities.filter(
        (val) => !this.selectedEntities.includes(val)
      );
      this.deleteEntitiesDialog = false;
      this.selectedEntities = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("user.multiple_deleted"),
        life: 3000,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
</style>
