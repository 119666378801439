<template>
  <Dialog :visible="containerVisible" :style="{ width: '600px' }" :header="$t('manifestitem.dialog.form_header')"
    :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="name" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('manifestitem.name') }}</label>
      <form-field :message="errors.name" class="col-12 md:col-10">
        <InputText ref="name" id="name" v-model.trim="currentEntity.name" autofocus :required="true" />
      </form-field>
    </div>
    <div class="field grid">
      <label for="unit" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('manifestitem.unit') }}</label>
      <form-field class="col-12 md:col-10" :message="errors.unit">
        <InputText ref="unit" id="unit" v-model.trim="currentEntity.unit" :required="true" autofocus type="text" />
      </form-field>
    </div>
    <div class="field grid">
      <label for="price" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('manifestitem.price') }}</label>
      <div class="col-12 md:col-10 flex">
        <span class="p-inputgroup-addon">$</span>
        <InputNumber ref="price" id="price" v-model.trim="currentEntity.price" :required="false" autofocus type="text" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel" />
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
import AutoCompleteValue from '@/components/form/AutoCompleteValue'
import FormField from '@/components/FormField'
import form from '@/mixins/form'
//import { useForm, useField } from 'vee-validate';
export default {
  components: { AutoCompleteValue, FormField },
  name: 'form-partner',
  mixins: [form],
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancel', 'save', 'hide'],
  data() {
    return {
      submitted: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      partnerTypes: [],
      usStates: [],
      vnProvinces: [],
      selectedPartnerType: null,
      errors: {},
    }
  },
  methods: {
    onChangePartnerType() {
      //this.currentEntity.partnerType = event.value.value;
    },
    onCancel() {
      this.containerVisible = false;
      this.currentEntity = {};
      this.$emit('cancel');
    },
    onSave() {
      if (!this.submitted) {
        this.errors = this.validate(this.currentEntity, 'manifestitem');
        if (Object.keys(this.errors).length == 0) {
          this.submitted = true;
          this.$emit('save', this.currentEntity);
        }
      }
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  mounted() {
  },
  updated() {
    this.currentEntity = this.entity;
    this.containerVisible = this.visible;
  },
}
</script>
