import http, {Service} from './common'
const resource = 'manifestitem'
class ManifestItemService extends Service {
  async import(infos, data) {
    const res = await http.post(`/${this.resource}/import`, { ...infos, data });
    return res.data;
  }
  // async getReceiptManifests(receiptIds) {
  //   const options = {
  //     filter: JSON.stringify({

  //     })
  //   };
  //   const manifests = await super.getAll(options);
  //   return manifests;
  // }
  loadAll() {
    return http.get(`/${resource}/all`)
  }

  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`)
  }
}

export default new ManifestItemService(resource)
